<template>
  <div class="discount-info">
    <p>🎉 При заказе от 10 упаковок - скидка 50₽ от каждой упаковки! 🎉</p>
  </div>
  <!-- <button @click="toggleCart">
    toggle
  </button> -->
  <div v-if="!isShowCart">
    <div v-for="(items, category) in coffeeData" :key="category">
      <h2 class="section-heading">{{ category }}</h2>
      <div class="coffee-container">
        <div v-for="coffee in items" :key="coffee.name" class="coffee-list">
          <CoffeeCard :coffee="coffee" :quantity="getQuantity(coffee.name)" @update-quantity="updateFromCart" />
        </div>
      </div>
    </div>
  </div>

  <div v-if="isShowCart">
    <CoffeeCart :cart="cart" @edit-cart="handleOpenShowCase" @send-cart="handleSendCart"></CoffeeCart>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import CoffeeCard from './components/CoffeeCard.vue'
import CoffeeCart from './components/CoffeeCart.vue'
import coffeeData from './coffeeData.js'

export default {
  name: 'NespressoShop',
  components: {
    CoffeeCard,
    CoffeeCart
  },
  setup() {
    onMounted(() => {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.MainButton.setParams({
          text: 'Посмотреть заказ'
        });
        window.Telegram.WebApp.MainButton.show();
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleOpenCart);
      } else {
        console.error("Telegram WebApp script not loaded.");
      }
    });

    const cart = ref([]);
    const coffeeDataReactive = reactive(coffeeData);
    let isShowCart = ref(false);

    function addToCart(item) {
      const existingItem = cart.value.find(cartItem => cartItem.item.name === item.name);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        cart.value.push({ item: item, quantity: 1 });
      }
    }

    function updateFromCart({ coffee, change }) {
      const itemIndex = cart.value.findIndex(cartItem => cartItem.item.name === coffee.name);
      if (itemIndex !== -1) {
        cart.value[itemIndex].quantity += change;
        if (cart.value[itemIndex].quantity <= 0) {
          cart.value.splice(itemIndex, 1);
        }
      } else if (change > 0) {
        addToCart(coffee);
      }
    }

    function getQuantity(name) {
      const item = cart.value.find(cartItem => cartItem.item.name === name);
      return item ? item.quantity : 0;
    }

    function handleOpenCart() {
      isShowCart.value = true;

      window.Telegram.WebApp.onEvent('backButtonClicked', handleOpenShowCase);
      window.Telegram.WebApp.offEvent('mainButtonClicked', handleOpenCart);
    }

    function handleOpenShowCase() {
      isShowCart.value = false;

      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.MainButton.setParams({
        text: 'Посмотреть заказ'
      });
      window.Telegram.WebApp.onEvent('mainButtonClicked', handleOpenCart);
    }

    function toggleCart() {
      if (isShowCart.value) {
        handleOpenShowCase();
      } else {
        handleOpenCart();
      }
    }

    function handleSendCart(cartSummary) {
      const cartForSending = cart.value.map(item => ({
        name: item.item.name,
        quantity: item.quantity,
        price: item.item.price
      }));

      const dataToSend = JSON.stringify({cart: cartForSending, summary: cartSummary});
      window.Telegram.WebApp.sendData(dataToSend);
      cart.value = [];
    }

    return {
      coffeeData: coffeeDataReactive,
      cart,
      isShowCart,
      addToCart,
      updateFromCart,
      getQuantity,
      toggleCart,
      handleOpenShowCase,
      handleSendCart
    };
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--tg-theme-secondary-bg-color);
}

.discount-info {
  margin: 0;
  padding: 5px;
  color: var(--tg-theme-text-color);
  font-size: 20px;
}

.coffee-container {
  padding: 0 1%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  align-items: start;
}

@media (max-width: 450px) {
  .coffee-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.coffee-list {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-heading {
  padding: 5px;
  color: var(--tg-theme-subtitle-text-color);
}
</style>
