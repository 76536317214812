<template>
    <div class="cart-section">
        <div class="cart-header">
            <h2>Ваш заказ</h2>
            <button class="edit-button" @click="handleEditMode">Редактировать</button>
        </div>

        <div class="cart-container">
            <div v-for="cartItem in cart" :key="cartItem.item.name" class="cart-item">
                <div class="item-image">
                    <img :src="`pics/${cartItem.item.imgUrl}`" :alt="cartItem.item.name" />
                </div>

                <div class="item-details">
                    <h3>{{ cartItem.item.name }} x{{ cartItem.quantity }}</h3>
                    <p class="item-description">{{ cartItem.item.description }}</p>
                </div>


                <div class="item-price">
                    <p>{{ cartItem.item.price * cartItem.quantity }}₽</p>
                </div>
            </div>
        </div>

        <div class="cart-summary">
            <div v-if="totalDiscount > 0">
                <p class="original-price"><s>{{ totalPrice + totalDiscount }}₽</s></p>
                <p>Итого со скидкой: {{ totalPrice }}₽</p>
                <p>Скидка: {{ totalDiscount }}₽</p>
            </div>
            <div v-else>
                Итого: {{ totalPrice }}₽
            </div>
            <div v-if="coffeeRubles > 0">
                Вам будет начилено {{ coffeeRubles }} коферублей!
            </div>
        </div>
    </div>

    <div class="comment-section">
        <input type="text" v-model="comment" class="comment-input" placeholder="Комментарий к заказу" />
        <p class="comment-descr">
            Дополнительные пожелания, детали
        </p>
    </div>

</template>



<script>
import { onMounted, ref, computed } from 'vue';

export default {
    props: {
        cart: Array,
    },
    emits: ['edit-cart', 'send-cart'],
    setup(props, { emit }) {
        onMounted(() => {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.MainButton.setParams({
                text: 'Отправить заказ',
                isVisible: true
            });
            window.Telegram.WebApp.onEvent('mainButtonClicked', sendCart);
            console.log('Opened');
        })

        let comment = ref('');

        function sendCart() {
            emit('send-cart', { comment: comment.value, totalQty: totalQuantity.value, totalPrice: totalPrice.value, totalDiscount: totalDiscount.value, coffeeRubles: coffeeRubles.value });
        }

        function handleEditMode() {
            emit('edit-cart');
        }

        // Compute total quantity
        const totalQuantity = computed(() => {
            return props.cart.reduce((total, item) => total + item.quantity, 0);
        });

        // Compute total discount
        const totalDiscount = computed(() => {
            if (totalQuantity.value >= 10) {
                return 50 * totalQuantity.value;
            }
            return 0;
        });

        // Calculate total price with potential discount
        const totalPrice = computed(() => {
            let total = 0;
            props.cart.forEach(item => {
                let discount = totalQuantity.value >= 10 ? 50 : 0;
                total += (item.item.price - discount) * item.quantity;
            });
            return total;
        });

        const coffeeRubles = computed(() => {
            const price = totalPrice.value;
            return Math.round(price/100*3);
        });

        return { comment, sendCart, handleEditMode, totalQuantity, totalPrice, totalDiscount, coffeeRubles }
    }
}
</script>

<style scoped>
.cart-section {
    width: 100%;
    background-color: var(--tg-theme-bg-color);
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
}

.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.cart-header h2 {
    text-transform: uppercase;
    color: var(--tg-theme-text-color);
}


.cart-item {
    display: grid;
    grid-template-columns: min-content 1fr 10%;
    gap: 16px;
    align-items: center;
    margin-bottom: 20px;
}

.item-image img {
    width: 100px;
    height: auto;
    border-radius: 8px;
}

.item-details h3,
.item-details p,
.item-price p {
    margin: 0;
}

.item-details {
    color: var(--tg-theme-text-color);
}

.item-description {
    color: var(--tg-theme-hint-color);
}

.item-price p {
    text-align: right;
    padding-right: 16px;
    color: var(--tg-theme-text-color);
}

.comment-input {
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 4px;
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-bg-color);
    resize: vertical;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.comment-descr {
    padding: 0 20px;
    color: var(--tg-theme-hint-color);
}

.edit-button {
    border: none;
    color: var(--tg-theme-link-color);
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.cart-summary {
    color: var(--tg-theme-text-color);
}
</style>