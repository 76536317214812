<template>
  <div class="coffee-card">
    <div class="row image-row">
      <img :src="`pics/${coffee.imgUrl}`" alt="" class="coffee-image" />
    </div>
    <div class="row name-row">{{ coffee.name }}</div>
    <div class="row intensity-row">
      <span v-for="n in 12" :key="n" class="circle" :class="{ 'filled': n <= coffee.intensity }"></span>
    </div>
    <div class="row descr-row">{{ coffee.description }}</div>
    <div class="row price-row"><span>₽{{ coffee.price }}</span><br><span class="price-hint">цена за 10 капсул</span></div>
    <div class="row button-row">
      <button v-if="quantity > 0" @click="decrement">-</button>
      <span v-if="quantity > 0">{{ quantity }}</span>
      <button v-if="quantity > 0" @click="increment">+</button>
      <button v-else @click="initialAddToCart">Заказать</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coffee: Object,
    quantity: Number,
  },
  emits: ['update-quantity'],
  setup(props, { emit }) {
    const increment = () => emit('update-quantity', { coffee: props.coffee, change: 1 });
    const decrement = () => emit('update-quantity', { coffee: props.coffee, change: -1 });
    const initialAddToCart = () => emit('update-quantity', { coffee: props.coffee, change: 1 });

    return { increment, decrement, initialAddToCart };
  },
};
</script>

<style scoped>
.coffee-card {
  display: grid;
  padding: 10px;
  grid-template-rows: auto auto auto auto auto auto;
  /* 6 rows */
  gap: 4px;
  background: var(--tg-theme-bg-color);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.image-row img {
  width: 100%;
  height: auto;
}

.name-row {
  height: 50px;
  color: var(--tg-theme-text-color);
}

.descr-row {
  height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--tg-theme-hint-color);
}

.price-row {
  flex-direction: column;
  color: var(--tg-theme-text-color);
}

.price-hint {
  color: var(--tg-theme-hint-color);
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 0 20px;
  color: var(--tg-theme-button-color);
}

.intensity-row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2px;
  height: 30px;
  align-items: center;
  justify-items: center;
}

.circle {
  width: 100%;
  height: 0; 
  padding-top: 100%; 
  border-radius: 50%; 
  background-color: var(--tg-theme-hint-color);
  position: relative;
  box-sizing: content-box; 
}

.filled {
  background-color: var(--tg-theme-link-color);
}

.circle::before {
  content: "";
  display: block;
  padding-top: 100%;
}

button {
  padding: 10px;
  background-color: #4CAF50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}
</style>